import { Component, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { Router } from "@angular/router";
import { Hotel, HotelDocument } from "src/app/models/hotel.model";
import { HotelAdmin } from "src/app/models/hotelAdmin";
import { FirebaseAuthService } from "src/app/services/shared/firebase-auth.service";

declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
}
export const ROUTES: RouteInfo[] = [
	{
		path: "/lista-prodotti",
		title: "Lista Prodotti",
		icon: "ni-bullet-list-67 text-blue",
		class: "",
	},
	{
		path: "/lista-hotel",
		title: "Lista Hotel",
		icon: "ni-building text-blue",
		class: "",
	},
	{
		path: "/user-profile",
		title: "Profilo utente",
		icon: "ni-single-02 text-blue",
		class: "",
	},
	/*{ path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
    { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' },*/
];

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
	public menuItems: any[];
	public isCollapsed = true;
	hotelAdmin : HotelAdmin;
	
	isLoading = false;

	constructor(
		private router: Router,
		public firebaseAuthService: FirebaseAuthService,
		private storage: AngularFireStorage
	) {}

	async ngOnInit() {
		this.isLoading = true;
		this.menuItems = ROUTES.filter((menuItem) => menuItem);
		this.router.events.subscribe((event) => {
			this.isCollapsed = true;
		});

		await this.firebaseAuthService.tryAutoLogin();

		this.hotelAdmin = this.firebaseAuthService.getHotelAdmin();
	
		this.isLoading = false;

	}
	async logout() {
		try {
			await this.firebaseAuthService.logout();
			console.log("Logging out");

			this.router.navigate(["login"]);
		} catch (e) {
			console.log(e);
		}
		//this.isLogout.emit()
	}
	
}
