import { environment } from "src/environments/environment";

export const statusMap = {
    0: "Da notificare",
    1: "Notificato",
    2: "Pagato",
    3: "Rifiutato",
    4: "Spedito"
};

export function getHotelCollection(){
    if(environment.production){
      return 'Hotel';
    }else{
      return 'HotelTest';
    }
  }
  
  export function getHotelAdminCollection(){
    if(environment.production){
      return 'HotelAdmin';
    }else{
      return 'HotelAdminTest';
    }
  }
  
  export function getHotelCustomerCollection(){
    if(environment.production){
      return 'HotelCustomerCollection';
    }else{
      return 'HotelCustomerCollectionTest';
    }
  }
  
  export function getAllProductsFunction(){
    if(environment.production){
      return 'getAllProducts';
    }else{
      return 'getAllProductsTest';
    }
  }
  
  //CAMELCASE
  export function getUpdateProductStatusFunction(){
    if(environment.production){
      return 'updateProductStatus';
    }else{
      return 'updateProductStatusTest';
    }
  }
  
  export function getAllHotelsFunction(){
    if(environment.production){
      return 'getAllHotels';
    }else{
      return 'getAllHotelsTest';
    }
  }
  
  export function getRemoveHotelFunction(){
    if(environment.production){
      return 'removeHotel';
    }else{
      return 'removeHotelTest';
    }
  }

  export function getStripeKey(){
    if(environment.production){
      return 'pk_live_51Id9kQBio1qLEirdyhx3mjnrJ7R6J4NEyzhtK2DTwhJoFgFGQovLdKb0ECySxa7W7fZmIGORcPpaDVtKwsmnIHlr00UJHVrIDB';
    }else{
      return 'pk_test_51Id9kQBio1qLEirdjZtTdxTVyfbNhe4n3HFwQeySQ7UqluWKTFSEzgZtqUQPwUQTWgAMZScpTkvYbcolZzjMBldr00thEfa5nk';
    }
  }

  export function getRedirectUrl(){
    if(environment.production){
      return 'https://us-central1-lostandfound-7f282.cloudfunctions.net';
    }else{
      return 'https://lostandfound-testing.firebaseapp.com/'
    }
  }