import { Component, OnInit, ElementRef } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import {
	Location,
	LocationStrategy,
	PathLocationStrategy,
} from "@angular/common";
import { Router } from "@angular/router";
import { EventEmitter } from "events";
import { Output } from "@angular/core";
import { FirebaseAuthService } from "src/app/services/shared/firebase-auth.service";
import { HotelAdmin } from "src/app/models/hotelAdmin";
import { AngularFireStorage } from "@angular/fire/storage";
import { Hotel } from "src/app/models/hotel.model";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
	public focus;
	public listTitles: any[];
	public location: Location;
	hotelAdmin: HotelAdmin;
	hotelLogo: string;
	hotel: Hotel;
	isLoading = false

	//@Output() isLogout = new EventEmitter<void>()

	constructor(
		location: Location,
		private element: ElementRef,
		private router: Router,
		private storage: AngularFireStorage,
		public firebaseAuthService: FirebaseAuthService
	) {
		this.location = location;
	}

	async ngOnInit() {
		this.isLoading = true;
		this.listTitles = ROUTES.filter((listTitle) => listTitle);

		await this.firebaseAuthService.tryAutoLogin();

		this.hotelAdmin = this.firebaseAuthService.getHotelAdmin();
		this.hotel = this.firebaseAuthService.getHotel()
		if(this.hotel && this.hotel.imagePath!=""){
			this.fetchImage(this.hotel.imagePath)
		}
		this.isLoading = false;
	}
	getTitle() {
		var titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === "#") {
			titlee = titlee.slice(1);
		}

		for (var item = 0; item < this.listTitles.length; item++) {
			if (this.listTitles[item].path === titlee) {
				return this.listTitles[item].title;
			}
		}
		return "Dashboard";
	}

	async fetchImage(path: string) {
		this.hotelLogo = await this.storage.ref(path).getDownloadURL().toPromise();
		
	}

	async logout() {
		try {
			await this.firebaseAuthService.logout();
			this.router.navigate(["login"]);
		} catch (e) {
			console.log(e);
		}
	}
}
