import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { HomeLoginComponent } from "./prove/components/home-login/home-login.component";
import { PasswordDimenticataComponent } from "./components/password-dimenticata/password-dimenticata.component";
import { ListaProdottiComponent } from "./components/lista-prodotti/lista-prodotti.component";
import { NuovoProdottoComponent } from "./components/nuovo-prodotto/nuovo-prodotto.component";
import { ListaHotelComponent } from "./components/lista-hotel/lista-hotel.component";
import { NuovoHotelComponent } from "./components/nuovo-hotel/nuovo-hotel.component";

import {
	AngularFireAuthGuard,
	canActivate,
	hasCustomClaim,
	redirectLoggedInTo,
	redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { PaymentComponent } from "./pages/payment/payment.component";

/*import { 
  AuthGuardService as AuthGuard 
} from './services/shared/auth-guard.service';*/

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToDashboard = () =>
	redirectLoggedInTo(["lista-prodotti"]);
//const adminOnly = () => hasCustomClaim('admin');

const routes: Routes = [
	{
		path: "",
		redirectTo: "login",
		pathMatch: "full",
	},
	{
		path: "login",
		component: LoginComponent,
		canActivate: [AngularFireAuthGuard],
		data: { authGuardPipe: redirectLoggedInToDashboard },
	},
	/*{
		path: "payment",
		component: PaymentComponent,
		canActivate: [],
		//data: { authGuardPipe: redirectUnauthorizedToLogin }
	},*/
	{
		path: "",
		component: AdminLayoutComponent,
		children: [
			{
				path: "",
				loadChildren:
					() => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnauthorizedToLogin },
			},
		],
	},
	{
		path: "",
		component: AuthLayoutComponent,
		children: [
			{
				path: "",
				loadChildren:
					() => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
				//canActivate: [AngularFireAuthGuard],
				//data: { authGuardPipe: redirectUnauthorizedToLogin }
			},
		],
	} /*{
    path: '**',
    redirectTo: 'dashboard'
  },*/,
	/*{
    path: 'lista-prodotti',
    component: ListaProdottiComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'nuovo-prodotto',
    component: NuovoProdottoComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'lista-hotel',
    component: ListaHotelComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'nuovo-hotel',
    component: NuovoHotelComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  }*/
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
}),
	],
	exports: [],
})
export class AppRoutingModule {}
