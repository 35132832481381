import { Component, OnInit, OnDestroy } from "@angular/core";
import { FirebaseAuthService } from "../../services/shared/firebase-auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Hotel, HotelDocument } from "src/app/models/hotel.model";
import { HotelAdmin } from "src/app/models/hotelAdmin";
import {
	AngularFireStorage,
	AngularFireStorageReference,
} from "@angular/fire/storage";
import { HotelServiceService } from "src/app/services/hotel-service.service";
import { AngularFireFunctions } from "@angular/fire/functions";
import { getHotelAdminCollection, getHotelCollection } from "../../variables/var";
//import { HotelAdmin } from "src/app/models/hotelAdmin";
//import { UserDocumentService } from "src/app/services/user-document.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	isSignedIn = false;
	isRegister = false;
	wrongEmailPassword = false;
	loading = false;

	hotel: HotelDocument;
	hotelAdmin: HotelAdmin;
	showPassword: boolean;
	password: string;
	imageFile: any;

	constructor(
		public firebaseAuthService: FirebaseAuthService,
		private router: Router,
		private afStorage: AngularFireStorage,
		private hotelService: HotelServiceService,
		private route: ActivatedRoute,
		private functions: AngularFireFunctions
	) {}

	ngOnInit() {
		//set localstorage to avoid problem if page is refreshed
		localStorage.setItem("modifyHotel", "false");
		localStorage.setItem("modifyProduct", "false");

		this.firebaseAuthService.autoLogin().then(() => {
			this.router.navigate(["/lista-prodotti"]);
		});

		this.wrongEmailPassword = false;
		var body = document.getElementsByTagName("body")[0];
		body.classList.add("bg-default");

		if (this.hotel == undefined) {
			let h: HotelDocument = {
				hotelAdminUid: "",
				mail: "",
				name: "",
				address: "",
				legalEntity: "",
				PEC: "",
				VAT: "",
				phone: "",
				id: "", //??
			};
			this.hotel = h;

			let admin: HotelAdmin = {
				uid: "",
				hotelId: "",
				email: "",
				firstName: "",
				lastName: "",
				phone: "",
				username: "",
				isSuperAdmin: false,
			};
			this.hotelAdmin = admin;
		}

		this.showPassword = false;

		if(this.route.snapshot.queryParams["register"]=='true'){
			this.isRegister = true;
		}
		
	}

	async onSignin(email: string, password: string) {
		//this.wrongEmailPassword = !this.wrongEmailPassword
		try {
			this.loading = true;
			let result = await this.firebaseAuthService.signin(email, password);

			if (result) {
				this.isSignedIn = result != null;
				this.router.navigate(["/lista-prodotti"]);
				this.wrongEmailPassword = false;
			} else {
				this.wrongEmailPassword = true;
			}
			this.loading = false;
		} catch (e) {
			this.wrongEmailPassword = true;
			this.loading = false;
		}
	}

	goToPasswordDimenticataComponent() {
		this.router.navigate(["/password-dimenticata"]);
	}

	toggleShowPassword() {
		this.showPassword = !this.showPassword;
	}

	caricaImmagine(event) {
		try {
			this.imageFile = event.target.files[0];
			this.hotel.imagePath = this.hotelAdmin.hotelId + "/logo.png";
		} catch (e) {
			alert(e);
		}
	}

	async uploadFile() {
		try {
			const ref: AngularFireStorageReference = this.afStorage.ref(
				this.hotel.imagePath
			);
			await ref.put(this.imageFile);
		} catch (e) {
			alert(e);
		}
	}

	CheckValidity() {
		if (
			this.hotel.name == "" ||
			this.hotel.mail == "" ||
			this.hotel.address == "" ||
			this.hotel.legalEntity == "" ||
			this.hotel.PEC == "" ||
			this.hotel.VAT == "" ||
			this.hotel.phone == ""
		) {
			return false;
		}
		if (this.hotel.id == "") {
			//creation: check validity for admin fields
			if (
				this.hotelAdmin.email == "" ||
				this.hotelAdmin.firstName == "" ||
				this.hotelAdmin.lastName == "" ||
				this.hotelAdmin.phone == "" ||
				this.hotelAdmin.username == "" ||
				this.password == ""
			) {
				return false;
			}
		}
		return true;
	}

	async AggiungiNuovoHotel() {
		if (!this.CheckValidity()) {
			return;
		}

		this.loading = true;
		try {
			let h: Hotel = {
				hotelAdminUid: "",
				mail: this.hotel.mail != undefined ? this.hotel.mail : "",
				name: this.hotel.name != undefined ? this.hotel.name : "",
				address: this.hotel.address != undefined ? this.hotel.address : "",
				streetNumber:
					this.hotel.streetNumber != undefined ? this.hotel.streetNumber : "",
				legalEntity:
					this.hotel.legalEntity != undefined ? this.hotel.legalEntity : "",
				PEC: this.hotel.PEC != undefined ? this.hotel.PEC : "",
				VAT: this.hotel.VAT != undefined ? this.hotel.VAT : "",
				phone: this.hotel.phone != undefined ? this.hotel.phone : "",
				city: this.hotel.city != undefined ? this.hotel.city : "",
				cap: this.hotel.cap != undefined ? this.hotel.cap : "",
				nation: this.hotel.nation != undefined ? this.hotel.nation : "",
				imagePath:
					this.hotel.imagePath != undefined ? this.hotel.imagePath : "",
			};
			if (this.imageFile != undefined && this.imageFile != "") {
				await this.uploadFile();
			}

			const resId = await this.firebaseAuthService.registerAndLogin(
				this.hotelAdmin.email,
				this.password
			);

			
			if (resId) {
				let newAdminRef = this.hotelService.createEmptyDocumentWithId(
					getHotelAdminCollection(),
					resId
				);
				h.hotelAdminUid = resId;

				this.hotelAdmin.uid = resId;
				let hotelRef = await this.hotelService.createOggetto(h, getHotelCollection());
				this.hotelAdmin.hotelId = hotelRef.id;

				await this.hotelService.setDocumentData(newAdminRef, this.hotelAdmin);

				//Mail utente registrato
				let callable = this.functions.httpsCallable("sendWelcomeEmail");
				const emailDataNew = {
					name: this.hotelAdmin.firstName,
					email: this.hotelAdmin.email,
				};
				await callable(emailDataNew).toPromise();

				//Mail utente registrato
				 callable = this.functions.httpsCallable("sendMailNewHotel");
				const emailDataAdmin = {
					hotelName: this.hotel.name,
				};
				await callable(emailDataAdmin).toPromise();


				this.loading = false;
				this.isRegister = false;
				window.location.reload();
			}

			this.loading = false;
			
			
		} catch (e) {
			alert(e);
		}
	}
}
