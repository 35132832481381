import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";

import { Router } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Hotel, HotelDocument } from "src/app/models/hotel.model";
import { HotelAdmin } from "src/app/models/hotelAdmin";
import { getHotelAdminCollection, getHotelCollection } from "../../variables/var";

@Injectable({
	providedIn: "root",
})
export class FirebaseAuthService {
	isLoggedIn = false;
	isUserCreated = false;
	hotelAdmin: HotelAdmin;
	hotel: Hotel;
	hotelId: string;
	//currentUser : firebase.

	currentUser: any = null;
	//private authStatusSub = new BehaviorSubject(this.currentUser);
	//public currentAuthStatus = this.authStatusSub.asObservable();

	constructor(
		public firebaseAuth: AngularFireAuth,
		private firestore: AngularFirestore,
		private router: Router,
		private functions: AngularFireFunctions
	) {
	}

	

	async tryAutoLogin() {
		if (this.currentUser == undefined || this.currentUser == null) {
			await this.autoLogin();
		}
	}

	async resetPassword(email: string){
		return await this.firebaseAuth.sendPasswordResetEmail(email)

	}

	async autoLogin() {
		this.currentUser = await this.firebaseAuth.currentUser;
		let user = this.currentUser;
		if (user) {
			this.isLoggedIn = true;
			//localStorage.setItem("user", JSON.stringify(res.user));
			try {
				let hotelAdminObservable = this.firestore
					.collection(getHotelAdminCollection())
					.doc(user.uid)
					.get();

				let doc = await hotelAdminObservable.toPromise();
				this.hotelAdmin = doc.data();
				this.hotelAdmin.uid = doc.id;

				let hotelObservable = this.firestore
					.collection(getHotelCollection())
					.doc(this.hotelAdmin.hotelId)
					.get();

				let docHotel = await hotelObservable.toPromise();
				this.hotel = docHotel.data();
				//this.hotelDoc = docHotel.data();
				this.hotelId = docHotel.id;

			} catch (e) {
				console.log(e);
				this.logout();
			}
			return true;
		}
	}

	//Login
	async signin(email: string, password: string) {
		this.isLoggedIn = false;
		try {
			let res = await this.firebaseAuth.signInWithEmailAndPassword(
				email,
				password
			);

			if (res != null) {
				this.isLoggedIn = true;
				//localStorage.setItem("user", JSON.stringify(res.user));
				try {
					let hotelAdminObservable = this.firestore
						.collection(getHotelAdminCollection())
						.doc(res.user.uid)
						.get();

						

					let doc = await hotelAdminObservable.toPromise();
					this.hotelAdmin = doc.data();
					this.hotelAdmin.uid = doc.id;

					let hotelObservable = this.firestore
						.collection(getHotelCollection())
						.doc(this.hotelAdmin.hotelId)
						.get();

					let docHotel = await hotelObservable.toPromise();
					this.hotel = docHotel.data();
					this.hotelId = docHotel.id;
				} catch (e) {
					console.log(e);
				}
				return true;
			} else {
				this.isLoggedIn = false;
				return false;
			}
		} catch (error) {
			this.isLoggedIn = false;
			return false;
		}
	}

	//Registration (to review)
	async signup(email: string, password: string) {
		this.isUserCreated = false;

		const callable = this.functions.httpsCallable("createUser");
		const res = await callable({ email, password }).toPromise();

		if (res === null) {
			this.isUserCreated = false;
			console.log("utente non creato");
			return null;
		} else {
			this.isUserCreated = true;
			return res.uid;
		}
	}

	async registerAndLogin(email: string, password: string) {
		try {
			const res = await this.firebaseAuth.createUserWithEmailAndPassword(
				email,
				password
			);

			

			return res.user.uid;
		} catch (e) {
			alert(e);
			return undefined;
		}
	}

	async logout() {
		await this.firebaseAuth.signOut();
		this.isLoggedIn = false;
		//localStorage.removeItem("user");
	}

	getHotelAdmin(): HotelAdmin {
		return this.hotelAdmin;
	}

	getHotel(): Hotel {
		return this.hotel;
	}

	getHotelId(): string {
		return this.hotelId;
	}
}
