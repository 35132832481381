import { Injectable, OnInit } from "@angular/core";
import {
	AngularFirestore,
	AngularFirestoreCollection,
	AngularFirestoreDocument,
	DocumentReference,
	DocumentSnapshot,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { getHotelCollection } from "../variables/var";
import { Hotel, HotelDocument } from "../models/hotel.model";
import { HotelAdmin } from "../models/hotelAdmin";
import { Product, ProductDocument } from "../models/product";
import { FirebaseAuthService } from "./shared/firebase-auth.service";

@Injectable({
	providedIn: "root",
})
export class HotelServiceService implements OnInit {
	productsCollections: AngularFirestoreCollection<Product>;
	products: Observable<ProductDocument[]>;

	hotelsCollection: AngularFirestoreCollection<Hotel>;
	hotels: Observable<HotelDocument[]>;
	hotelAdmin: HotelAdmin;
	hotel: Observable<HotelDocument>;

	constructor(
		private firestore: AngularFirestore,
		public firebaseAuthService: FirebaseAuthService
	) {}

	ngOnInit() {}

	async loadData() {

		if(this.hotelAdmin == undefined || this.hotelAdmin != this.firebaseAuthService.getHotelAdmin()){
			this.products = undefined;
		}

		await this.firebaseAuthService.tryAutoLogin();

		this.hotelAdmin = this.firebaseAuthService.getHotelAdmin();
		
		if (this.products == undefined && this.hotelAdmin != undefined && !this.hotelAdmin.isSuperAdmin) {
			this.products = this.getProducts(
				`${getHotelCollection()}/${this.hotelAdmin.hotelId}/Products`
			);
		}

		if (this.hotel == undefined && this.hotelAdmin != undefined) {
			if (this.hotelAdmin.isSuperAdmin) {
				this.hotels = this.getAllHotels(getHotelCollection());
			} else {
				this.hotel = this.getHotel(getHotelCollection(), this.hotelAdmin.hotelId);
			}
		}

	}

	isUserSuperAdmin() {
		return this.hotelAdmin.isSuperAdmin.valueOf();
	}


	getProducts(path: string) {
		try {
			let result = this.firestore
				.collection(path, (ref) => ref.orderBy("insertionDate", 'desc'))
				.snapshotChanges()
				.pipe(
					map((actions) =>
						actions.map((a) => {
							const data = a.payload.doc.data() as any;
							const id = a.payload.doc.id;
							let prodotto: ProductDocument = {
								address: data.address,
								category: data.category,
								dateFound: data.dateFound,
								dateFoundString: data.dateFoundString,
								description: data.description,
								ownerMail: data.ownerMail,
								ownerName: data.ownerName,
								finderName: data.finderName,
								height: data.height,
								depth: data.depth,
								length: data.length,
								hotelId: data.hotelId,
								kg: data.kg,
								maxHoldTime: data.maxHoldTime,
								name: data.name,
								ownerId: data.ownerId,
								shippingInfo: data.shippingInfo,
								status: data.status,
								phone: data.phone,
								imagePath: data.imagePath,
								weightCategoryId: data.weightCategoryId,
								regionId: data.regionId,
								city: data.city,
								cap: data.cap,
								streetNumber: data.streetNumber,
								foundPlace: data.foundPlace,
								country: data.country,
								courier: data.courier,
								shippingNumber: data.shippingNumber,
								insertionDate: data.insertionDate,
								notes: data.notes,
								conservationPlace: data.conservationPlace,
								id: id,
								clientTaxId: data.clientTaxId,
								partitaIva: data.partitaIva,
								sdi: data.sdi
							};
							return prodotto;
							//return
							//{ id, ...data };
						})
					)
				);
			return result;
			//this.firestore.collection<any>(type).get();
		} catch (e) {
			alert(e);
		}
	}

	getHotel(path: string, docId: string): Observable<DocumentSnapshot<any>> {
		try {
			let result: Observable<any> = this.firestore
				.collection(path)
				.doc(docId)
				.get();
			return result;
			//this.firestore.collection<any>(type).get();
		} catch (e) {
			alert(e);
		}
	}

	getHotelAdmin(
		path: string,
		docId: string
	): Observable<DocumentSnapshot<any>> {
		try {
			let result: Observable<any> = this.firestore
				.collection(path)
				.doc(docId)
				.get();
			return result;
			//this.firestore.collection<any>(type).get();
		} catch (e) {
			alert(e);
		}
	}

	getAllHotels(path: string) {
		try {
			let result = this.firestore
				.collection(path)
				//.where
				.snapshotChanges()
				.pipe(
					map((actions) =>
						actions.map((a) => {
							const data = a.payload.doc.data() as any;
							const id = a.payload.doc.id;
							return { id, ...data };
						})
					)
				);
			return result;
			//this.firestore.collection<any>(type).get();
		} catch (e) {
			alert(e);
		}
	}

	async createOggetto(item: Product | Hotel | HotelAdmin, type: string) {
		try {
			let result = await this.firestore.collection(type).add(item);
			return result;
			// .then(res => {alert("fatto")}, err => alert(err));
		} catch (e) {
			alert(e);
		}
	}

	/*createEmptyDocument(type: string){
    let result = this.firestore
      .collection(type)
      .doc()
    return result;
  }*/

	async addNewDocument(path: string, data: any) {
		let result: DocumentReference = await this.firestore
			.collection(path)
			.add(data);
		return result;
	}

	createEmptyDocument(path: string) {
		let result = this.firestore.collection(path).doc();
		return result;
	}

	createEmptyDocumentWithId(path: string, id: string) {
		let result = this.firestore.collection(path).doc(id);
		return result;
	}

	async setDocumentData(
		document: AngularFirestoreDocument,
		content: Product | Hotel | HotelAdmin
	) {
		await document.set(content);
	}

	async updateObject(item: Product | Hotel | HotelAdmin, path: string) {
		//delete hotel.id;
		try {
			let result = await this.firestore.doc(path).update(item);
			return result;
		} catch (e) {
			alert(e);
		}
	}
}
