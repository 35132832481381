import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { ControlContainer, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";

import { FirebaseAuthService } from "./services/shared/firebase-auth.service";
import { HomeLoginComponent } from "./prove/components/home-login/home-login.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireStorageModule } from "@angular/fire/storage";

import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { PasswordDimenticataComponent } from "./components/password-dimenticata/password-dimenticata.component";
import { ListaProdottiComponent } from "./components/lista-prodotti/lista-prodotti.component";
import { NuovoProdottoComponent } from "./components/nuovo-prodotto/nuovo-prodotto.component";
import { NuovoHotelComponent } from "./components/nuovo-hotel/nuovo-hotel.component";

import { HotelServiceService } from "./services/hotel-service.service";
import { ListaHotelComponent } from "./components/lista-hotel/lista-hotel.component";

import { UpdateHotelService } from "./services/update-hotel.service";
import { UpdateProductService } from "./services/update-product.service";
import { AuthGuardService } from "./services/shared/auth-guard.service";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";

import { ClipboardModule } from "ngx-clipboard";
import { PaymentComponent } from './pages/payment/payment.component';

//import { AngularFireModule } from '@angular/fire'

@NgModule({
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		ClipboardModule,
		ComponentsModule,
		NgbModule,
		RouterModule,
		AppRoutingModule,
		AngularFireModule.initializeApp({
			apiKey: "AIzaSyBlUm07t-7ybQs1jqF7_a5ulT_body8f20",
			authDomain: "lostandfound-7f282.firebaseapp.com",
			projectId: "lostandfound-7f282",
			storageBucket: "lostandfound-7f282.appspot.com",
			messagingSenderId: "214311552030",
			appId: "1:214311552030:web:c1fd4454a61a0f5ee19a0f",
		}),
		AngularFireAuthModule,
		AngularFireStorageModule,
		AngularFireDatabaseModule,
		//ControlContainer
		//CommonModule,
		//BrowserModule
		//AngularFireModule.initializeApp()
	],
	declarations: [
		AppComponent,
		AdminLayoutComponent,
		AuthLayoutComponent,
		//PasswordDimenticataComponent,
		LoginComponent,
		//PaymentComponent,
		//RegisterComponent
	],
	providers: [
		FirebaseAuthService,
		HotelServiceService,
		UpdateHotelService,
		UpdateProductService,
		AngularFireAuth,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
