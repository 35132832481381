import { Injectable } from '@angular/core';
import { Hotel, HotelDocument } from '../models/hotel.model';

@Injectable({
  providedIn: 'root'
})
export class UpdateHotelService {

  hotelToUpdate : HotelDocument;

  constructor() { }

  setHotel(hotel: HotelDocument){
    this.hotelToUpdate= hotel;
  }
}
