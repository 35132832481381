import { Injectable } from '@angular/core';
import { Product, ProductDocument } from '../models/product';


@Injectable({
  providedIn: 'root'
})
export class UpdateProductService {

  productToUpdate : ProductDocument;

  constructor() { }
}
